body {
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden !important;
  overflow-y: visible !important;
  min-width: 320px;
  background: #fff;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
  font-smoothing: antialiased;
}
